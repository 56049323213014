<template>
<div class='card'>
    <h5 class='font-bold mb-0'>{{ t['434'] }}</h5>
    <Divider type='dashed' class='mt-0'></Divider>
    <DataTable :value='rolesList' :loading='loading' :paginator='true' :rows='20' :rowsPerPageOptions='[10, 20, 50]' class='p-datatable-sm'
               :filters='filters'
               :resizableColumns='true' columnResizeMode='fit'
               v-model:selection='roleSelection' selectionMode='single'>
        <div class='text-right mb-1'>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" :placeholder="t['82']" />
            </span>
            <Button label='//Add new Role' icon='pi pi-plus' @click='dialogVisible=true' class='ml-2'></Button>
        </div>
        <Column field='role' :header="'//ROLE'"></Column>
        <Column field='name' :header="t['116']"></Column>
        <Column>
            <template #body='slotProps'>
                <div style='text-align: center'>
                    <i class='pi pi-pencil' @click='openEditRole(slotProps.data.role)'  style='font-size: 1rem'></i>
                </div>
            </template>
        </Column>
    </DataTable>
    <Dialog v-model:visible='dialogVisible' header="//Nova vloga" :style="{width: '600px'}" :modal='true' :closable='false'>
        <Divider class='mb-4 mt-0'></Divider>
        <div class='ml-2'>
            <span class="p-float-label mb-4">
                <InputText id="newRoleCodeValue" type="text" v-model="newRoleCodeValue" />
                <label for="newRoleCodeValue" class='font-bold'>//Code</label>
            </span>
            <span class="p-float-label">
                <InputText id="newRoleServletValue" type="text" v-model="newRoleServletValue" />
                <label for="newRoleServletValueValue" class='font-bold'>//Servlet</label>
            </span>
        </div>
        <Divider></Divider>
        <div class='col-12 ml-0'>
            <div class='ml-2 mb-2'>
                <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-danger'/>
                <span class='font-light' style='font-size: 12px'>{{ '  ' + t['463'] }}</span>
                <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-warning ml-2'/>
                <span class='font-light' style='font-size: 12px'>{{ '  ' + t['464'] }}</span>
                <Button icon="pi" iconPos="right" style='font-size: 1px; width: 15px' class='p-button-success ml-2'/>
                <span class='font-light' style='font-size: 12px'>{{ '  ' + t['465'] }}</span>
            </div>
            <Divider type='dashed'></Divider>
            <div class='dashboard'>
                <div class='task-list'>
                    <ul>
                        <li v-for='item in newRoleTranslationList' :key='item.langCode' style='border-bottom: none; padding: 0px 0px;'>
                            <div class="p-inputgroup md:col-12">
                                <span class="p-inputgroup-addon" style='list-style-type: none;'>{{item.langCode}}</span>
                                <InputText placeholder="" v-model='item.name' style='width: 100%'/>
                                <Button v-bind:class="[item.status === 1 ? 'p-button-danger' : item.status === 2 ? 'p-button-warning' : 'p-button-success']" @click='item.status = ((item.status)%3)+1'></Button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Divider></Divider>
        <template #footer>
            <Button :label="t['86']" icon="pi pi-times" class='p-button-outlined' @click="dialogVisible=false"/>
            <Button :label="t['87']" icon="pi pi-check" class='p-button-outlined' @click="verifyNewRoleInputs()"/>
        </template>
    </Dialog>
</div>
</template>

<script>
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import { computed } from 'vue';
import axios from 'axios';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'Roles',
    setup() {
        const store = useStore()
        const primeVue = usePrimeVue()

        return {
            token: computed(() => store.state.bearerToken),
            t: primeVue.config.locale,
        }
    },
    data() {
        return {
            rolesList: null,
            loading: true,
            filters: null,
            roleSelection: null,
            dialogVisible: null,
            dialogNameList: null,
            newRoleCodeValue: null,
            newRoleServletValue: null,
            newRoleTranslationList: [],
        }
    },
    created() {
        this.initFilter()
    },
    mounted() {
        this.getRoles()
        this.getAllLanguages()
    },
    methods: {
        getRoles() {
            this.loading = true
            axios
                .post('/administration/role/list/', {
                    'criteria': [
                        {'fn':'arlrole', 'eq':'=', 'fv':''},
                        {'fn':'atrname', 'eq':'LIKE', 'fv':''}
                    ],
                    'orderByField':'arlrole',
                    'direction':'ASC'
                }, {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then(res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                        this.rolesList = res.data.retObj
                        this.loading = false
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error  => {
                    console.log(error);
                })
        },
        getAllLanguages() {
            this.newRoleTranslationList = []
            axios
                .get( 'app/webObjects/dropdown/ALL_LANGUAGES/VALUE/false/get',  {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then( res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0){
                        let dropdownLang = res.data.retObj.dropDownList

                        for(let i = 0; i < dropdownLang.length; i++) {
                            let langObj = {
                                'roleCode': null,
                                'langCode': dropdownLang[i].key,
                                'name': null,
                                'status': 1
                            }
                            this.newRoleTranslationList.push(langObj)
                        }
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error => {
                    console.log("There was an error loading text ID list!")
                    console.log(error)
                })
        },
        verifyNewRoleInputs() {
            // TODO: Add regex to verify Inputs
            // const regex = /^[A-Z]*$/
            // if(regex.test(this.newRoleCodeValue)) {
            //
            // }
            this.createNewRole()
        },
        createNewRole() {
            for(let i = 0; i < this.newRoleTranslationList.length; i++) {
                this.newRoleTranslationList[i].roleCode = this.newRoleCodeValue
            }
            axios
                .post('/administration/role/create', {
                    'role': this.newRoleCodeValue,
                    'servlet': this.newRoleServletValue,
                    'roleTranslations': this.newRoleTranslationList
                }, {
                    headers: {
                        Authorization: this.token
                    }
                })
                .then(res => {
                    if(res.status === 200 && res.data.rmsList[0].retCode === 0) {
                        this.openEditRole(res.data.retObj.role)
                        this.loading = false
                    }else {
                        this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                    }
                })
                .catch(error  => {
                    console.log(error);
                })
        },
        openEditRole(roleName) {
            this.$router.push('/editrole/'+roleName)
        },
        initFilter() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        clearFilter() {
            this.initFilter()
        },
    }
};
</script>

<style scoped>

</style>